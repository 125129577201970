// New order capture flow
import Vue from 'vue'

import axios from "axios";

import Vuetify from "vuetify";
import './../../stylesheets/vuetify.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faCircleCheck, faCircleXmark, faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import NewBooking from "./../../vue/booking/NewBooking.vue";

Vue.use(Vuetify)
Vue.prototype.$axios = axios
library.add(faCircleCheck, faCircleXmark, faCircle, faMinusSquare, faPlusSquare)

let newBookingModalVueInstance

addEventListener('DOMContentLoaded', () => {
  const instantQuoteBtn = document.getElementsByClassName("get-instant-quote")
  const bookingModalEl = document.getElementById("instant-quote-modal")
  const bookingModalCloseBtn = document.getElementById("instant-quote-close-btn")
  const token = localStorage.getItem('token', '')
  if (token === null || token === '') {
    axios.get(`${window.location.origin}/api_tokens/new.json`)
      .then(response => {
        localStorage.setItem('token', response.data.jwt)
      })
  }
  bookingModalCloseBtn.addEventListener('click', () => {
    window.history.pushState({ path: window.location.origin }, '', window.location.origin)
    let body = document.getElementsByTagName("body")[0]
    body.style.overflow = "unset"
    if (bookingModalEl) {
      bookingModalEl.__x.$data.isBookingDialogOpen = false
    }
    newBookingModalVueInstance.$destroy()
    newBookingModalVueInstance.$el.remove()

    document.getElementById('instant-quote-modal-body').appendChild(
      Object.assign(
        document.createElement('div'),
        { id : 'vue-new-booking' }
      )
    )
  })
  
  for (var i = 0; i < instantQuoteBtn.length; i++) {
    instantQuoteBtn[i].addEventListener('click', () => {
      axios.get(`${window.location.origin}/v3/users/profile`, { headers: {
          Authorization: localStorage.getItem('token', ''),
        }
      })
      .then((response) => {
        if (!response.data.artist) {
          const fontAwesomeComponent = Vue.component('font-awesome-icon', FontAwesomeIcon)
          const newGigModalElementContainer = document.getElementById('vue-new-booking')
          if (newGigModalElementContainer != null) {
            const guestToken = localStorage.getItem("guest_token", "");
            if (guestToken) {
                Vue.prototype.$axios.defaults.baseURL = ''

                Vue.prototype.$axios.defaults.headers.common = {
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                  Accept: 'application/json',
                  'Content-Type': 'application/json; charset=utf-8'
                }
                let body = document.getElementsByTagName("body")[0]
                body.style.overflow = "hidden"
                newBookingModalVueInstance = new Vue({
                  el: newGigModalElementContainer,
                  vuetify: new Vuetify({
                    theme: {
                      themes: {
                        light: {
                          primary: '#ff8141',
                          secondary: '#FFF3EC'
                        },
                      }
                    }
                  }),
                  render: h => h(NewBooking, {}),
                  components: {
                    NewBooking,
                    fontAwesomeComponent
                  }
                })
            }
            else {
              axios.post(`${window.location.origin}/v3/event_bookings/clear.json`, { headers: {
                  Authorization: localStorage.getItem('token', ''),
                }
              })
              .then(() => {
                Vue.prototype.$axios.defaults.baseURL = ''

                Vue.prototype.$axios.defaults.headers.common = {
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                  Accept: 'application/json',
                  'Content-Type': 'application/json; charset=utf-8'
                }
                let body = document.getElementsByTagName("body")[0]
                body.style.overflow = "hidden"
                newBookingModalVueInstance = new Vue({
                  el: newGigModalElementContainer,
                  vuetify: new Vuetify({
                    theme: {
                      themes: {
                        light: {
                          primary: '#ff8141',
                          secondary: '#FFF3EC'
                        },
                      }
                    }
                  }),
                  render: h => h(NewBooking, {}),
                  components: {
                    NewBooking,
                    fontAwesomeComponent
                  }
                })
              })
            }
            
            if (bookingModalEl) {
              bookingModalEl.__x.$data.isBookingDialogOpen = true
            }
          }
        } else {
          window.location.href = '/dashboard'
        }
      })
    })
    axios.get(`${window.location.origin}/v3/users/profile`, { headers: {
        Authorization: localStorage.getItem('token', ''),
      }
    })
    .then((response) => {
      let new_booking = window.location.href.includes("instant_quote")
      const guestToken = localStorage.getItem("guest_token", "");
      if((guestToken && response.data.role === 'regular') || new_booking) {
        instantQuoteBtn[0].click();
      }
      else {
        localStorage.removeItem("guest_token");
      }
    })
  }
})
// End